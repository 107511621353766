<template>
    <div class="main-box">
        <div class="con-box">
            <a-table :bordered="true" :columns="columns" :data-source="xxLists" :loading="loading" :scroll="{ x: 1600 }" :pagination="pagination" @change="onPage" style="margin-top: 20px;">
                <template #action="{record}" v-if="xxLists.length != 0">
                    <img src="@/assets/images/edit.png" style="margin-right: 10px;" @click="$router.push({ path: '/ReportForms/JYSJDetail', query: { id: record.JYDATA_XIAOXIANG_ID, num: record.xuhao } })">
                    <a-popconfirm
                        title="确定要删除此条记录吗?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="onDel(record)"
                        @cancel="cancel"
                    >
                        <img src="@/assets/images/del.png">
                    </a-popconfirm>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
const columns = [
  { title: '序号', width: 100, dataIndex: 'xuhao', key: 'xuhao' },
  { title: '发票种类', width: 100, dataIndex: 'fapiaozhonglei', key: 'fapiaozhonglei' },
  { title: '发票代码', width: 100, dataIndex: 'fapiaodaima', key: 'fapiaodaima' },
  { title: '发票号码', width: 100, dataIndex: 'fapiaohaoma', key: 'fapiaohaoma' },
  { title: '发票机号', width: 100, dataIndex: 'fapiaojihao', key: 'fapiaojihao' },
  { title: '购方名称', width: 100, dataIndex: 'goufangmingcheng', key: 'goufangmingcheng' },
  { title: '购方税号', width: 100, dataIndex: 'goufangshuihao', key: 'goufangshuihao' },
  { title: '购方地址电话', width: 150, dataIndex: 'goufangdizhidianhua', key: 'goufangdizhidianhua' },
  { title: '购方银行账号', width: 150, dataIndex: 'goufangyinhangzhanghao', key: 'goufangyinhangzhanghao' },
  { title: '开票日期', width: 100, dataIndex: 'kaipiaoriqi', key: 'kaipiaoriqi' },
  { title: '报送状态', width: 100, dataIndex: 'baosongzhuangtai', key: 'baosongzhuangtai' },
  { title: '报送日志', width: 100, dataIndex: 'baosongrizhi', key: 'baosongrizhi' },
  { title: '所属月份', width: 100, dataIndex: 'suoshuyuefen', key: 'suoshuyuefen' },
  { title: '合计金额', width: 100, dataIndex: 'hejijine', key: 'hejijine' },
  { title: '税率', width: 100, dataIndex: 'shuilv', key: 'shuilv' },
  { title: '合计税额', width: 100, dataIndex: 'hejishuie', key: 'hejishuie' },
  { title: '主要商品名称', width: 150, dataIndex: 'zhuyaoshangpinmingcheng', key: 'zhuyaoshangpinmingcheng' },
  { title: '商品税目', width: 100, dataIndex: 'shangpinshuimu', key: 'shangpinshuimu' },
  { title: '开票人', width: 100, dataIndex: 'kaipiaoren', key: 'kaipiaoren' },
  { title: '收款人', width: 100, dataIndex: 'shoukuanren', key: 'shoukuanren' },
  { title: '打印标志', width: 100, dataIndex: 'dayinbiaozhi', key: 'dayinbiaozhi' },
  { title: '作废标志', width: 100, dataIndex: 'zuofeibiaozhi', key: 'zuofeibiaozhi' },
  { title: '清单标志', width: 100, dataIndex: 'qingdanbiaozhi', key: 'qingdanbiaozhi' },
  { title: '修复标志', width: 100, dataIndex: 'xiufubiaozhi', key: 'xiufubiaozhi' },
  { title: '复合人', width: 100, dataIndex: 'fuheren', key: 'fuheren' },
  { title: '作废日期', width: 100, dataIndex: 'zuofeiriqi', key: 'zuofeiriqi' },
  { title: '备注', width: 100, dataIndex: 'beizhu', key: 'beizhu' },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    fixed: 'right',
    width: 100,
    slots: { customRender: 'action' }
  }
]
export default {
    computed: {
        ...mapGetters([
            'orgId',
            'loginType',
            'isAuth',
            'isEdit',
            'addXXStatus',
            'y'
        ])
    },
    watch: {
        addXXStatus: function (newVal) {
            if (newVal) {
                this.getXXFPList(this.y)
            }
        },
      y: function (newVal) {
        if (newVal) {
          this.getXXFPList(this.y)
        }
      }
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            file: '',
            columns,
            loading: false,
            xxLists: [],
            pagination: {},
            page: 1,
            pageCount: 10
        }
    },
    methods: {
        // 获取销项发票列表
        getXXFPList (y) {
            this.loading = true
            this.xxLists = []
            this.$store.dispatch('getXXFPList', { showCount: this.pageCount, currentPage: this.page, ENTERPRISE_ID: this.orgId, YEAR: y, tm: new Date().getTime() }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    this.$store.commit('SET_XXSTATUS', false)
                    if (res.varList.length != 0) {
                        this.pagination.total = res.page.totalResult
                        let datas = res.varList
                        for (let i in datas) {
                            this.xxLists.push({
                                'key': parseInt(i) + 1,
                                'JYDATA_XIAOXIANG_ID': datas[i].JYDATA_XIAOXIANG_ID,
                                'xuhao': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'fapiaozhonglei': datas[i].FAPIAO_KIND,
                                'fapiaodaima': datas[i].FAPIAO_CODE,
                                'fapiaohaoma': datas[i].FAPIAO_NUMBER,
                                'fapiaojihao': datas[i].FAPIAO_MACHINE,
                                'goufangmingcheng': datas[i].GOUAGENT,
                                'goufangshuihao': datas[i].GOUSHUIHAO,
                                'goufangdizhidianhua': datas[i].GOUADDRPHONE,
                                'goufangyinhangzhanghao': datas[i].GOU_BANKCODE,
                                'kaipiaoriqi': datas[i].KAIPIAO_DATE,
                                'baosongzhuangtai': datas[i].BAOSONG_STATUS,
                                'baosongrizhi': datas[i].BAOSONG_LOG,
                                'suoshuyuefen': datas[i].SUOSHUMONTH,
                                'hejijine': datas[i].HEJIMONEY,
                                'shuilv': datas[i].SHUILV,
                                'hejishuie': datas[i].TOTAL_SHUIE,
                                'zhuyaoshangpinmingcheng': datas[i].PRIMARYAGENT,
                                'shangpinshuimu': datas[i].SPSM,
                                'kaipiaoren': datas[i].KAIPIAOREN,
                                'shoukuanren': datas[i].SHOUKUANREN,
                                'dayinbiaozhi': datas[i].DAYINBIAOZHI,
                                'zuofeibiaozhi': datas[i].ZUOFEIBIAOZHI,
                                'qingdanbiaozhi': datas[i].QINGDANBIAOZHI,
                                'xiufubiaozhi': datas[i].XIUFUBIAOZHI,
                                'fuheren': datas[i].FUHEREN,
                                'zuofeiriqi': datas[i].ZUOFEIDATE,
                                'beizhu': datas[i].BEIZHU
                            })
                        }
                    } else {
                        this.pagination.total = 0
                        this.xxLists = []
                    }
                } else {
                    this.pagination.total = 0
                    this.xxLists = []
                    this.$message.error('系统异常，获取列表失败，请稍后重试！')
                }
            }).catch(e => {
                this.loading = false
                this.$message.error('系统异常，获取列表失败，请稍后重试！')
            })
        },
        // 翻页
        onPage (pagination) {
            this.page = pagination.current
            this.getXXFPList(this.y)
        },
        // 删除单条销项发票
        onDel (item) {
            this.$store.dispatch('deleteXXFPItem', { JYDATA_XIAOXIANG_ID: item.JYDATA_XIAOXIANG_ID, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    this.getXXFPList(this.y)
                } else {
                    this.$message.success('删除失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.getXXFPList(this.y)
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            border-bottom: 1px solid #bbbbbb;
            .con-item{
                width: 50%;
            }
        }
    }
}
</style>
